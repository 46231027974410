<template>
<div>
  <div v-if="date">
    <table>
      <tbody>
        <tr>
          <td style="padding-right: 2rem; vertical-align: top;">
            <div class="text-primary">
              <label for="example-input">Дата:</label>
              <b-input-group class="mb-1">
                <b-form-input
                    id="example-input"
                    v-model="date"
                    type="text"
                    placeholder="ДД.ММ.ГГГГ"
                    autocomplete="off"
                />
                <b-input-group-append>
                  <b-form-datepicker
                      v-model="date"
                      size="sm"
                      button-only
                      locale="ru-RU"
                      aria-controls="example-input"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-if="qrySaldo">
    <table class="tbl">
      <thead>
        <tr style="border-bottom: solid black 1px;">
          <th>Валюта</th>
          <th>Курс</th>
          <th>Сумма в валюте</th>
          <th>Сумма в KZT</th>
          <th>Сумма в USD</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="cur in currencies()" :key="cur.curname.value">
          <td style="text-align: center;">{{ cur.curname.value }}</td>
          <td style="text-align: right;">{{ cur.fxrate.value }}</td>
          <td style="text-align: right;">{{ totalAmountCur(cur.currency.value).toLocaleString() }}</td>
          <td style="text-align: right;">{{ totalAmountCurKzt(cur.currency.value).toLocaleString() }}</td>
          <td style="text-align: right;">{{ totalAmountCurUsd(cur.currency.value).toLocaleString() }}</td>
        </tr>
        <tr style="border-top: solid black 1px;">
          <td><b>ВСЕГО</b></td>
          <td></td>
          <td></td>
          <td style="text-align: right;"><b>{{ totalAmountKzt().toLocaleString() }}</b></td>
          <td style="text-align: right;"><b>{{ totalAmountUsd().toLocaleString() }}</b></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-if="qrySaldo" style="margin-top: 2rem; overflow: scroll;">
    <table class="table table-bordered tbl">
      <thead>
        <tr>
          <th>ID</th>
          <th>Код</th>
          <th>Наименование</th>
          <th>Тип счета</th>
          <th>Валюта</th>
          <th>Сумма в валюте счета</th>
          <th>Сумма в KZT</th>
          <th>Ставка по депозиту</th>
          <th>Начало депозита</th>
          <th>Окончание депозита</th>
          <th>Рейтинг S&P</th>
          <th>IBAN</th>
          <th>Описание</th>
        </tr>
      </thead>
      <tbody v-for="bank in qryBank.recordset.records" :key="bank.id.value">
        <tr style="background-color: lightcyan;">
          <td>
            <span style="cursor: pointer;" @click="expandBank(bank)">
              <feather-icon size="16" :icon="bank.cbs_expanded ? 'ChevronDownIcon' : 'ChevronRightIcon'" />
            </span>
          </td>
          <td>
            <cbs-budreq-cell :record="bank"
                             key-value="code"
                             value-type="string"
                             access="rw"
                             style="text-align: center;"
                             @edit="onEditBank(bank, 'code', $event)"
            />
          </td>
          <td>
            {{ bank.name.value }} ({{ accCount(bank) }})
            <b-badge pill variant="light-success"
                     title="Создать счет" style="cursor: pointer;" @click="createAcc(bank)">
              +
            </b-badge>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td style="text-align: right;">{{ amountKztBank(bank).toLocaleString() }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <cbs-budreq-cell :record="bank"
                             key-value="ratingsnp"
                             value-type="string"
                             access="rw"
                             style="text-align: left;"
                             @edit="onEditBank(bank, 'ratingsnp', $event)"
            />
          </td>
          <td></td>
          <td>
            <cbs-budreq-cell :record="bank"
                             key-value="descr"
                             value-type="string"
                             access="rw"
                             style="text-align: left;"
                             @edit="onEditBank(bank, 'descr', $event)"
            />
          </td>
        </tr>
        <tr v-for="acc in accounts(bank)" :key="acc.id.value">
          <td style="text-align: right;">{{ acc.id.value }}</td>
          <td>
            <cbs-budreq-cell :record="acc"
                             key-value="code"
                             value-type="string"
                             access="rw"
                             style="text-align: center;"
                             @edit="onEditAcc(acc, 'code', $event)"
            />
          </td>
          <td>
            <cbs-budreq-cell :record="acc"
                             key-value="name"
                             value-type="string"
                             access="rw"
                             style="text-align: right;"
                             @edit="onEditAcc(acc, 'name', $event)"
            />
          </td>
          <td style="text-align: center;">
            <cbs-reference-picker3 :record="acc"
                                   :field="qryAcc.columns.find(c => c.entitysid === 'tres_acc_type')"
                                   :prop-ref="refAccType()" @updateCell="onEditAcc(acc, 'tres_acc_type', $event)"
                                   :key-mode="'sid'"
            />
          </td>
          <td style="text-align: center;">
            <cbs-reference-picker3 :record="acc"
                                   :field="qryAcc.columns.find(c => c.entitysid === 'currency')"
                                   :prop-ref="refCur()" @updateCell="onEditAcc(acc, 'currency', $event)"
                                   :key-mode="'sid'"
            />
          </td>
          <td style="padding: 0.5rem; background-color: lightyellow;">
            <cbs-budreq-cell :record="getRecord(acc)"
                             key-value="curamount"
                             value-type="number"
                             access="rw"
                             style="text-align: right;"
                             @edit="onEditSaldo(getRecord(acc), $event)"
            />
          </td>
          <td style="text-align: right;">{{ amountKzt(acc.currency.value, getRecord(acc).curamount.value).toLocaleString() }}</td>
          <td>
            <cbs-budreq-cell :record="acc"
                             key-value="fld_rate"
                             value-type="percent"
                             access="rw"
                             style="text-align: center;"
                             @edit="onEditAcc(acc, 'fld_rate', $event)"
            />
          </td>
          <td>
            <cbs-budreq-cell :record="acc"
                             key-value="start"
                             value-type="date"
                             access="rw"
                             style="text-align: center;"
                             @edit="onEditAcc(acc, 'start', $event)"
            />
          </td>
          <td>
            <cbs-budreq-cell :record="acc"
                             key-value="finish"
                             value-type="date"
                             access="rw"
                             style="text-align: center;"
                             @edit="onEditAcc(acc, 'finish', $event)"
            />
          </td>
          <td></td>
          <td>
            <cbs-budreq-cell :record="acc"
                             key-value="iban"
                             value-type="string"
                             access="rw"
                             style="text-align: right;"
                             @edit="onEditAcc(acc, 'iban', $event)"
            />
          </td>
          <td>
            <cbs-budreq-cell :record="acc"
                             key-value="descr"
                             value-type="string"
                             access="rw"
                             style="text-align: left;"
                             @edit="onEditAcc(acc, 'descr', $event)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <cbs-debug v-if="isRoot" :context="this" />
</div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useCubus from '@/cubus/services/useCubus'
import {
  BBadge,
  BFormDatepicker, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import CbsBudreqCell from '@/cubus/components/cbs-form/custom-forms/budget-request/CbsBudreqCell.vue'
import CbsReferencePicker3 from '@/cubus/components/reference/CbsReferencePicker3.vue'

export default {
  name: 'CbsTresSaldoIn',
  components: {
    BBadge,
    CbsReferencePicker3,
    CbsBudreqCell,
    CbsDebug,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormDatepicker,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      date: null,
      qryBank: null,
      qryAcc: null,
      qryRates: null,
      qrySaldo: null,
      qryAccType: null,
      qryCur: null,
      ratesReady: false,
    }
  },
  setup() {
    return {
      isRoot: useCubus.isRoot(),
      notDelCond: useCubus.cond_notDel(),
      notDelFilter: useCubus.filter_notDel(),
      orderByCode: useCubus.orderby_code(),
    }
  },
  created() {
    this.init()
  },
  watch: {
    date(newVal, oldVal) {
      this.initData()
    },
  },
  methods: {
    async init() {
      await this.initDate()
      await this.initData()
    },
    initDate() {
      const d = new Date()
      this.date = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${d.getDate()}`
    },
    async initData() {
      await this.initRates()
      await this.loadAccTypes()
      await this.loadCurrencies()
      await this.loadQryBank()
      await this.loadAccounts()
      await this.loadSaldo()
    },
    async initRates() {
      await this.loadRates()
      if (this.qryRates.recordset.records.length === 0) {
        await this.loadNbRkRates()
        await this.loadRates()
      }
      this.ratesReady = true
    },
    async loadRates() {
      this.qryRates = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'nbrkcurrates',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'del' },
                  { type: { sid: 'value' }, value: false },
                ],
              },
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'date' },
                  { type: { sid: 'value' }, value: this.date },
                ],
              },
              {
                isactive: true,
                oper: { sid: 'isnotnull' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'currency' },
                ],
              },
            ],
          },
        },
        orderby: {
          isActive: true,
          sorts: [
            {
              isActive: true,
              oper: { sid: 'asc' },
              entity: { sid: 'curname' },
            },
          ],
        },
        limit: 1000,
      })
    },
    async loadNbRkRates() {
      await useCubus.runProcedure(this, {
        keymode: 'sid',
        objectsid: 'procgetcurratesnbrkdate',
        record: {
          start: { value: this.date, title: null },
          finish: { value: this.date, title: null },
        },
      })
    },
    async loadAccTypes() {
      this.qryAccType = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'tres_acc_type',
        filter: this.notDelFilter,
        orderby: this.orderByCode,
        limit: 1000,
      })
    },
    async loadCurrencies() {
      this.qryCur = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'currency',
        filter: this.notDelFilter,
        orderby: this.orderByCode,
        limit: 1000,
      })
    },
    async loadQryBank() {
      this.qryBank = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'tres_bank',
        filter: this.notDelFilter,
        orderby: this.orderByCode,
        limit: 1000,
      })
    },
    async loadAccounts() {
      this.qryAcc = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'tres_account',
        filter: this.notDelFilter,
        orderby: this.orderByCode,
        limit: 1000,
      })
    },
    async loadSaldo() {
      this.qrySaldo = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'tres_saldo_in',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              this.notDelCond,
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'date' },
                  { type: { sid: 'value' }, value: this.date },
                ],
              },
            ],
          },
        },
        limit: 1000,
      })
    },
    expandBank(bank) {
      if (!('cbs_expanded' in bank)) {
        this.$set(bank, 'cbs_expanded', true)
      } else {
        const b = bank
        b.cbs_expanded = !bank.cbs_expanded
      }
    },
    accounts(bank) {
      if (!bank.cbs_expanded) return []
      return this.qryAcc.recordset.records.filter(r => r.tres_bank.value === bank.id.value).sort((a, b) => a.code.value.localeCompare(b.code.value))
    },
    accCount(bank) {
      return this.qryAcc.recordset.records.filter(r => r.tres_bank.value === bank.id.value).length
    },
    getRecord(acc) {
      const rec = this.qrySaldo.recordset.records.find(r => r.tres_account.value === acc.id.value)
      if (rec) return rec
      const n = this.getNewRecord(acc)
      this.qrySaldo.recordset.records.push(n)
      return n
    },
    getNewRecord(acc) {
      const n = {}
      this.qrySaldo.columns.forEach(col => {
        if (col.key !== 'actions') {
          n[col.entitysid] = {}
          n[col.entitysid].value = col.default.value
          n[col.entitysid].title = col.default.title
        }
      })
      n.id.value = null
      n.id.title = null
      n.sid.value = `${useCubus.guid()}`
      n.sid.title = null
      n.date.value = this.date
      n.date.title = null
      n.tres_account.value = acc.id.value
      n.tres_account.title = acc.name.value
      return n
    },

    onEditSaldo(rec, newValue) {
      const b = rec
      b.curamount.value = newValue
      b.curamount.status = 'changed'
      this.saveRecordSaldo(rec)
    },
    async saveRecordSaldo(record) {
      try {
        const saved = await useCubus.saveRecord(this, { keymode: 'sid', objectsid: 'tres_saldo_in', record })
        this.afterSaveSaldo(record, saved)
      } catch (e) { console.error(e) }
    },
    afterSaveSaldo(initRec, savedRec) {
      const i = initRec
      const s = savedRec
      s.status = 'saved'
      this.qrySaldo.columns.filter(c => c.key !== 'actions').forEach(fld => {
        i[fld.entitysid].value = savedRec[fld.entitysid].value
        i[fld.entitysid].title = savedRec[fld.entitysid].title
      })
      i.status = 'saved'
      this.recalc()
    },

    onEditAcc(acc, key, newValue) {
      const b = acc
      if (key === 'tres_acc_type' || key === 'currency') {
        b[key].value = newValue.value
        b[key].title = newValue.title
        b[key].status = 'changed'
      } else if (key === 'fld_rate') {
        if (newValue === '') {
          b[key].value = null
        } else {
          b[key].value = newValue
        }
        b[key].status = 'changed'
        b[key].value = newValue
        b[key].status = 'changed'
      } else {
        b[key].value = newValue
        b[key].status = 'changed'
      }
      this.saveRecordAcc(acc)
    },
    async saveRecordAcc(record) {
      try {
        const saved = await useCubus.saveRecord(this, { keymode: 'sid', objectsid: 'tres_account', record })
        this.afterSaveAcc(record, saved)
      } catch (e) { console.error(e) }
    },
    afterSaveAcc(initRec, savedRec) {
      const i = initRec
      const s = savedRec
      s.status = 'saved'
      this.qryAcc.columns.filter(c => c.key !== 'actions').forEach(fld => {
        i[fld.entitysid].value = savedRec[fld.entitysid].value
        i[fld.entitysid].title = savedRec[fld.entitysid].title
        i[fld.entitysid].status = 'saved'
      })
      i.status = 'saved'
      this.recalc()
    },

    onEditBank(rec, key, newValue) {
      const b = rec
      b[key].value = newValue
      b[key].status = 'changed'
      this.saveRecordBank(rec)
    },
    async saveRecordBank(record) {
      try {
        const saved = await useCubus.saveRecord(this, { keymode: 'sid', objectsid: 'tres_bank', record })
        this.afterSaveBank(record, saved)
      } catch (e) { console.error(e) }
    },
    afterSaveBank(initRec, savedRec) {
      const i = initRec
      const s = savedRec
      s.status = 'saved'
      this.qryBank.columns.filter(c => c.key !== 'actions').forEach(fld => {
        i[fld.entitysid].value = savedRec[fld.entitysid].value
        i[fld.entitysid].title = savedRec[fld.entitysid].title
      })
      i.status = 'saved'
      this.recalc()
    },

    recalc() {
      console.log('recalc')
    },
    refAccType() {
      return this.qryAccType.recordset.records.map(r => ({ value: r.id.value, title: r.name.value }))
    },
    refCur() {
      return this.qryCur.recordset.records.map(r => ({ value: r.id.value, title: r.name.value }))
    },
    createAcc(bank) {
      const n = {}
      this.qryAcc.columns.forEach(col => {
        if (col.key !== 'actions') {
          n[col.entitysid] = {}
          n[col.entitysid].value = col.default.value
          n[col.entitysid].title = col.default.title
        }
      })
      n.id.value = null
      n.id.title = null
      n.sid.value = `${useCubus.guid()}`
      n.sid.title = null
      n.code.value = `${bank.code.value}.${String(this.accCount(bank) + 1).padStart(2, '0')}`
      n.code.title = null
      n.tres_bank.value = bank.id.value
      n.tres_bank.title = bank.name.value
      this.qryAcc.recordset.records.push(n)
    },

    curRate(curid) {
      const rate = this.qryRates.recordset.records.find(r => r.currency.value === curid)
      if (rate) return rate.fxrate.value
      return 1
    },
    amountKzt(curid, curamount) {
      return (curamount || 0) * this.curRate(curid)
    },
    amountCurAcc(acc) {
      const rec = this.qrySaldo.recordset.records.find(r => r.tres_account.value === acc.id.value)
      if (rec) return rec.curamount.value || 0
      return 0
    },
    amountKztAcc(acc) {
      const rec = this.qrySaldo.recordset.records.find(r => r.tres_account.value === acc.id.value)
      if (rec) return this.amountKzt(acc.currency.value, rec.curamount.value)
      return 0
    },
    amountKztBank(bank) {
      return this.qryAcc.recordset.records
        .filter(r => r.tres_bank.value === bank.id.value)
        .reduce((acc, crs) => acc + this.amountKztAcc(crs), 0)
    },
    totalAmountCur(curid) {
      return this.qryAcc.recordset.records
        .filter(r => r.currency.value === curid)
        .reduce((acc, crs) => acc + this.amountCurAcc(crs), 0)
    },
    totalAmountCurKzt(curid) {
      return this.totalAmountCur(curid) * this.curRate(curid)
    },
    totalAmountCurUsd(curid) {
      const usd = this.qryCur.recordset.records.find(r => r.code.value === 'USD')
      return this.totalAmountCurKzt(curid) / this.curRate(usd.id.value)
    },
    totalAmountKzt() {
      return this.qryCur.recordset.records
        .reduce((acc, cur) => acc + this.totalAmountCurKzt(cur.id.value), 0)
    },
    totalAmountUsd() {
      return this.qryCur.recordset.records
        .reduce((acc, cur) => acc + this.totalAmountCurUsd(cur.id.value), 0)
    },

    kztRate() {
      const kzt = this.qryCur.recordset.records.find(r => r.code.value === 'KZT')
      return {
        curname: { value: 'KZT', title: null },
        currency: { value: kzt.id.value, title: kzt.name.value },
        date: { value: this.date, title: null },
        fxrate: { value: 1, title: null },
        id: { value: null, title: null },
        sid: { value: null, title: null },
        del: { value: false, title: null },
      }
    },
    currencies() {
      return [...this.qryRates.recordset.records, this.kztRate()]
    },
  },
}
</script>

<style scoped>
.tbl > thead > tr > th {
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.tbl > tbody > tr > td {
  padding: 0.5rem 1rem 0.5rem 1rem;
}
</style>
