<template>
<div>
  <div style="min-height: 70vh; max-height: 80vh; overflow: scroll;">
    <table v-if="qryDeposit" class="cbs-tbl table table-bordered table-">
      <thead>
        <tr>
          <th>ID</th>
          <th>Наименование</th>
          <th>Статус</th>
          <th>Фин Инструмент</th>
          <th>Валюта</th>
          <th>Сумма Вклада</th>
          <th>Начало</th>
          <th>Окончание</th>
          <th>Кол-во дней</th>
          <th>Ставка</th>
          <th>Начисленное Вознаграждение</th>
          <th>Ставка Налога у источника</th>
          <th>Налог у источника</th>
          <th>Вознаграждение к получению</th>
          <th>Сумма закрытия</th>
        </tr>
      </thead>
      <tbody v-for="bank in qryBank.recordset.records" :key="bank.id.value">
        <tr style="background-color: lightcyan;">
          <td>
            <span style="cursor: pointer;" @click="expandBank(bank)">
              <feather-icon size="16" :icon="bank.cbs_expanded ? 'ChevronDownIcon' : 'ChevronRightIcon'" />
            </span>
          </td>
          <!--<td>{{ bank.code.value }}</td>-->
          <td>
            {{ bank.name.value }} ({{ depCount(bank) }})
            <b-badge pill variant="light-success"
                     title="Создать счет" style="cursor: pointer;" @click="createDep(bank)">
              +
            </b-badge>
          </td>
          <td colspan="7"></td>
          <td colspan="2">{{ bank.tres_interest_calculation_type.title }}</td>
          <td style="text-align: center;">
            {{ (!bank.withholding_tax_rate.value || bank.withholding_tax_rate.value === 0) ? '' : `${(Number(bank.withholding_tax_rate.value) * 100)}%` }}
          </td>
          <td colspan="3"></td>
        </tr>
        <tr v-for="dep in deposits(bank)" :key="dep.id.value">
          <td style="text-align: center; cursor: pointer;" :class="dep.locked.value ? 'text-danger' : 'text-success'" @click="toggleLock(dep)">
            <!--{{ dep.id.value }}-->
            <feather-icon :icon="dep.locked.value ? 'LockIcon' : 'UnlockIcon'" />
          </td>
          <!--<td style="background-color: lightyellow;">
            <cbs-budreq-cell :record="dep"
                             key-value="code"
                             value-type="string"
                             access="rw"
                             style="text-align: left;"
                             @edit="onEditDep(dep, 'code', $event)"
            />
          </td>-->
          <td :style="cellStyle(dep)">
            <cbs-budreq-cell :record="dep"
                             key-value="name"
                             value-type="string"
                             :access="dep.locked.value ? 'ro' : 'rw'"
                             style="text-align: left;"
                             @edit="onEditDep(dep, 'name', $event)"
            />
          </td>
          <td style="text-align: center;">{{ depStatus(dep) }}</td>
          <td :style="cellStyle(dep)">
            <span v-if="dep.locked.value">{{ dep.tres_ref_fin_instrument.title }}</span>
            <cbs-reference-picker3 v-else
                                   :record="dep"
                                   :field="qryDeposit.columns.find(c => c.entitysid === 'tres_ref_fin_instrument')"
                                   :prop-ref="refInstr()" @updateCell="onEditDep(dep, 'tres_ref_fin_instrument', $event)"
                                   :key-mode="'sid'"
            />
          </td>
          <td style="text-align: center;" :style="cellStyle(dep)">
            <span v-if="dep.locked.value">{{ dep.currency.title }}</span>
            <cbs-reference-picker3 v-else
                                   :record="dep"
                                   :field="qryDeposit.columns.find(c => c.entitysid === 'currency')"
                                   :prop-ref="refCur()" @updateCell="onEditDep(dep, 'currency', $event)"
                                   :key-mode="'sid'"
            />
          </td>
          <td :style="cellStyle(dep)">
            <cbs-budreq-cell :record="dep"
                             key-value="deposit_amount_cur"
                             value-type="number"
                             :access="dep.locked.value ? 'ro' : 'rw'"
                             style="text-align: right;"
                             @edit="onEditDep(dep, 'deposit_amount_cur', $event)"
            />
          </td>
          <td :style="cellStyle(dep)">
            <cbs-budreq-cell :record="dep"
                             key-value="start"
                             value-type="date"
                             :access="dep.locked.value ? 'ro' : 'rw'"
                             style="text-align: center;"
                             @edit="onEditDep(dep, 'start', $event)"
            />
          </td>
          <td :style="cellStyle(dep)">
            <cbs-budreq-cell :record="dep"
                             key-value="finish"
                             value-type="date"
                             :access="dep.locked.value ? 'ro' : 'rw'"
                             style="text-align: center;"
                             @edit="onEditDep(dep, 'finish', $event)"
            />
          </td>
          <td style="text-align: center;">
            {{ Number(dep.deposit_days.value).toLocaleString() }}
          </td>
          <td :style="cellStyle(dep)">
            <cbs-budreq-cell :record="dep"
                             key-value="fld_rate"
                             value-type="percent4"
                             :access="dep.locked.value ? 'ro' : 'rw'"
                             style="text-align: right;"
                             @edit="onEditDep(dep, 'fld_rate', $event)"
            />
          </td>
          <td style="text-align: right;">
            {{ formattedNumber(dep.deposit_interest_accr_cur.value) }}
          </td>
          <td :style="cellStyle(dep)">
            <cbs-budreq-cell :record="dep"
                             key-value="withholding_tax_rate"
                             value-type="percent"
                             :access="dep.locked.value ? 'ro' : 'rw'"
                             style="text-align: center;"
                             @edit="onEditDep(dep, 'withholding_tax_rate', $event)"
            />
          </td>
          <td style="text-align: right;">
            {{ formattedNumber(dep.withholding_amount_cur.value) }}
          </td>
          <td style="text-align: right;">
            {{ formattedNumber(dep.interest_amount_cur.value) }}
          </td>
          <td style="text-align: right;">
            {{ formattedNumber(dep.deposit_and_interest_amount_cur.value) }}
          </td>
        </tr>
      </tbody>
      <thead></thead>
    </table>
  </div>
  <cbs-debug v-if="isRoot" :context="this" />
</div>
</template>

<script>
import useCubus from '@/cubus/services/useCubus'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsReferencePicker3 from '@/cubus/components/reference/CbsReferencePicker3.vue'
import CbsBudreqCell from '@/cubus/components/cbs-form/custom-forms/budget-request/CbsBudreqCell.vue'
import {
  BBadge,
} from 'bootstrap-vue'

export default {
  name: 'CbsTresDeposit',
  components: {
    BBadge,
    CbsBudreqCell,
    CbsReferencePicker3,
    CbsDebug,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      qryBank: null,
      qryDeposit: null,
      qryCurrency: null,
      qryCalcType: null,
      qryFinInstr: null,
    }
  },
  setup() {
    return {
      isRoot: useCubus.isRoot(),
      notDelFilter: useCubus.filter_notDel(),
      orderByCode: useCubus.orderby_code(),
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.initData()
    },
    async initData() {
      this.qryCurrency = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'currency',
        filter: this.notDelFilter,
        orderby: this.orderByCode,
        limit: 1000,
      })
      this.qryCalcType = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'tres_interest_calculation_type',
        filter: this.notDelFilter,
        orderby: this.orderByCode,
        limit: 1000,
      })
      this.qryBank = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'tres_bank',
        filter: this.notDelFilter,
        orderby: this.orderByCode,
        limit: 1000,
      })
      this.qryFinInstr = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'tres_ref_fin_instrument',
        filter: this.notDelFilter,
        orderby: this.orderByCode,
        limit: 1000,
      })
      this.qryDeposit = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'tres_deposit',
        filter: this.notDelFilter,
        orderby: this.orderByCode,
        limit: 1000,
      })
    },

    expandBank(bank) {
      if (!('cbs_expanded' in bank)) {
        this.$set(bank, 'cbs_expanded', true)
      } else {
        const b = bank
        b.cbs_expanded = !bank.cbs_expanded
      }
    },
    deposits(bank) {
      if (!bank.cbs_expanded) return []
      return this.qryDeposit.recordset.records.filter(r => r.tres_bank.value === bank.id.value).sort((a, b) => a.code.value.localeCompare(b.code.value))
    },
    depCount(bank) {
      return this.qryDeposit.recordset.records.filter(r => r.tres_bank.value === bank.id.value).length
    },

    refCur() {
      return this.qryCurrency.recordset.records.map(r => ({ value: r.id.value, title: r.name.value }))
    },
    refInstr() {
      return this.qryFinInstr.recordset.records.map(r => ({ value: r.id.value, title: r.name.value }))
    },
    getBank(dep) {
      return this.qryBank.recordset.records.find(r => r.id.value === dep.tres_bank.value)
    },
    getCalcType(dep) {
      const bank = this.getBank(dep)
      if (!bank) return null
      return this.qryCalcType.recordset.records.find(r => r.id.value === bank.tres_interest_calculation_type.value)
    },

    onEditDep(dep, key, newValue) {
      const b = dep
      const fld = this.qryDeposit.columns.find(c => c.entitysid === key)
      if (fld.datatype === 'ref') {
        b[key].value = newValue.value
        b[key].title = newValue.title
        b[key].status = 'changed'
      } else if (fld.datatype === 'int' || fld.datatype === 'double') {
        if (newValue === '') {
          b[key].value = null
        } else {
          b[key].value = newValue
        }
        b[key].status = 'changed'
      } else {
        b[key].value = newValue
        b[key].status = 'changed'
      }
      this.recalcDep(dep)
      this.saveRecordDep(dep)
    },
    async saveRecordDep(record) {
      try {
        const saved = await useCubus.saveRecord(this, { keymode: 'sid', objectsid: 'tres_deposit', record })
        this.afterSaveDep(record, saved)
      } catch (e) { console.error(e) }
    },
    afterSaveDep(initRec, savedRec) {
      const i = initRec
      const s = savedRec
      s.status = 'saved'
      this.qryDeposit.columns.filter(c => c.key !== 'actions').forEach(fld => {
        i[fld.entitysid].value = savedRec[fld.entitysid].value
        i[fld.entitysid].title = savedRec[fld.entitysid].title
        i[fld.entitysid].status = 'saved'
      })
      i.status = 'saved'
    },

    recalcDep(dep) {
      if (!dep.start.value || !dep.finish.value || dep.start.value === '' || dep.start.value === '0' || dep.finish.value === '' || dep.finish.value === '0') {
        this.clearDep(dep)
      } else {
        this.calcDays(dep)
        this.calcInterest(dep)
        this.calcTax(dep)
        this.calcInterestNet(dep)
        this.calcTotalAmount(dep)
      }
    },
    depStatus(dep) {
      const inputDate = new Date(dep.finish.value) // Заданная дата
      const today = new Date() // Текущая дата
      if (inputDate < today) return 'Закрыт'
      return 'Открыт'
    },
    getDaysBetween(startDate, finishDate) {
      const start = new Date(startDate)
      const finish = new Date(finishDate)

      // Обнуляем время у обеих дат
      start.setHours(0, 0, 0, 0)
      finish.setHours(0, 0, 0, 0)

      const diffMs = Math.abs(finish - start)
      return Math.round(diffMs / (1000 * 60 * 60 * 24))
    },
    calcDays(dep) {
      const d = dep
      const days = this.getDaysBetween(dep.start.value, dep.finish.value)
      d.deposit_days.value = days
      d.deposit_days.status = 'changed'
    },
    calcInterest(dep) {
      const d = dep
      const amount = dep.deposit_amount_cur.value
      const interestRate = dep.fld_rate.value
      const days = dep.deposit_days.value
      const calcType = this.getCalcType(dep)
      if (calcType) {
        if (calcType.sid.value === 'calendar') {
          const ranges = this.splitDateRange(dep.start.value, dep.finish.value)
          const interests = ranges.map(r => this.calcInterestActualDays(dep, r[0], r[1]))
          const interest = interests.reduce((acc, num) => acc + Number(num), 0)
          d.deposit_interest_accr_cur.value = interest
        } else if (calcType.sid.value === 'days365') {
          d.deposit_interest_accr_cur.value = (amount * interestRate * (days / 365)).toFixed(2)
        } else {
          d.deposit_interest_accr_cur.value = (amount * interestRate * (days / 360)).toFixed(2)
        }
      }
      d.deposit_interest_accr_cur.status = 'changed'
    },
    calcInterestActualDays(dep, start, finish) {
      const amount = dep.deposit_amount_cur.value
      const interestRate = dep.fld_rate.value
      const days = this.getDaysBetween(start, finish)
      const daysInYear = this.daysInYear(new Date(finish).getFullYear())
      return (amount * interestRate * (days / daysInYear)).toFixed(2)
    },
    splitDateRange(startDate, endDate) {
      const start = new Date(startDate)
      const end = new Date(endDate)

      if (start.getFullYear() === end.getFullYear()) {
        // Если даты в одном году, возвращаем исходный диапазон
        return [[start.toISOString().split('T')[0], end.toISOString().split('T')[0]]]
      }
      // Если даты в разных годах, создаем два диапазона
      const endOfYear = new Date(start.getFullYear(), 11, 31) // 31 декабря начального года
      // const startOfNextYear = new Date(end.getFullYear(), 0, 1) // 1 января следующего года

      return [
        [this.dateToISOString(start), this.dateToISOString(endOfYear)],
        [this.dateToISOString(endOfYear), this.dateToISOString(end)],
      ]
    },
    daysInYear(year) {
      const feb29 = new Date(year, 1, 29)
      const day = feb29.getDate()
      return (day === 29) ? 366 : 365
    },
    calcTax(dep) {
      const d = dep
      const taxRate = dep.withholding_tax_rate.value
      const interest = dep.deposit_interest_accr_cur.value
      d.withholding_amount_cur.value = (interest * taxRate).toFixed(2)
      d.withholding_amount_cur.status = 'changed'
    },
    calcInterestNet(dep) {
      const d = dep
      const interestGross = dep.deposit_interest_accr_cur.value
      const tax = dep.withholding_amount_cur.value
      d.interest_amount_cur.value = (interestGross - tax).toFixed(2)
      d.interest_amount_cur.status = 'changed'
    },
    calcTotalAmount(dep) {
      const d = dep
      const amountOpened = Number(dep.deposit_amount_cur.value)
      const interestNet = Number(dep.interest_amount_cur.value)
      const t = amountOpened + interestNet
      d.deposit_and_interest_amount_cur.value = t.toFixed(2)
      d.deposit_and_interest_amount_cur.status = 'changed'
    },
    clearDep(dep) {
      const d = dep
      d.deposit_days.value = 0
      d.deposit_days.status = 'changed'
      d.deposit_interest_accr_cur.value = 0
      d.deposit_interest_accr_cur.status = 'changed'
      d.withholding_amount_cur.value = 0
      d.withholding_amount_cur.status = 'changed'
      d.interest_amount_cur.value = 0
      d.interest_amount_cur.status = 'changed'
      d.deposit_and_interest_amount_cur.value = d.deposit_amount_cur.value
      d.deposit_and_interest_amount_cur.status = 'changed'
    },

    createDep(bank) {
      const n = {}
      this.qryDeposit.columns.forEach(col => {
        if (col.key !== 'actions') {
          n[col.entitysid] = {}
          n[col.entitysid].value = col.default.value
          n[col.entitysid].title = col.default.title
        }
      })
      n.id.value = null
      n.id.title = null
      n.sid.value = `${useCubus.guid()}`
      n.sid.title = null
      n.code.value = `${bank.code.value}.${String(this.depCount(bank) + 1).padStart(4, '0')}`
      n.code.title = null
      n.tres_bank.value = bank.id.value
      n.tres_bank.title = bank.name.value
      n.withholding_tax_rate.value = bank.withholding_tax_rate.value
      this.qryDeposit.recordset.records.push(n)
    },

    toggleLock(dep) {
      this.onEditDep(dep, 'locked', !dep.locked.value)
    },

    cellStyle(dep) {
      if (dep.locked.value) {
        return {}
      }
      return {
        backgroundColor: 'lightyellow',
      }
    },

    dateToISOString(dt) {
      // Извлекаем компоненты
      const year = dt.getFullYear()
      const month = String(dt.getMonth() + 1).padStart(2, '0') // Месяцы начинаются с 0
      const day = String(dt.getDate()).padStart(2, '0') // Добавляем ведущий ноль
      // Форматируем строку в формате DD.MM.YYYY
      // return `${day}.${month}.${year}`
      return `${year}-${month}-${day}`
    },

    formattedNumber(num) {
      return new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num)
    },
  },
}
</script>

<style scoped>
.cbs-tbl > thead > tr > th {
  padding: 0.5rem 1rem 0.5rem 1rem;
  position: sticky;
  top: 0;
  z-index: 2;
}
.cbs-tbl > tbody > tr > td {
  padding: .5rem .5rem .5rem .5rem;
}
.cbs-num {
  font-variant-numeric: tabular-nums;
}
</style>
