<template>
  <div>
    <div class="tablespace">
      <table v-if="dataReady" class="table table-bordered">
        <thead>
          <tr>
            <th :id="`firstcol_${uuid}`" class="stick-th-1" >Код</th>
            <th class="stick-th-2 stick-two" :style="styleStick2()">Показатель</th>
            <th class="stick-th" >Ед.Изм</th>
            <th v-for="mn in monthnames" :key="'hm_' + mn" class="stick-th">{{ mn + '-' + (baseYear - 2000) }}</th>
            <th class="stick-th">{{ baseYear }}</th>
            <th v-for="yr in years" :key="'hy_' + yr" class="stick-th">{{ baseYear + yr }}</th>
          </tr>
        </thead>
        <tbody v-for="ind in indicators" :key="'ind_' + ind.id.value" style="border-bottom: 0; border-top: 0;">
          <tr v-if="!isByProduct(ind) && !ind.isfolder.value">
            <td class="table-light cell" style="position: sticky; left: 0; z-index: 2;">
              {{ ind.code.value }}
            </td>
            <td class="table-light cell stick-two" style="position: sticky; z-index: 2;" :style="styleStick2()">
              <feather-icon v-if="isByProduct(ind)"
                            size="16" style="cursor: pointer;"
                            :icon="ind.expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'"
                            @click="clickIndExpand(ind)"
              />
              {{ ind.name.value }}
            </td>
            <td style="padding: 0; text-align: center;">{{ ind[keyMetric].title }}</td>
            <td v-for="mnth in months" :key="'mnth_' + mnth" style="padding: 0.25rem 0.5rem 0.25rem 0.5rem;"
                :id="mnth < 12 ? `kpi_mn_${ind.id.value}_${mnth}_${uuid}` : ''">
              <cbs-budreq-cell :record="getRecord(constrDateString('month', mnth), ind, null)"
                               :key-value="keyVal"
                               :indicator="ind"
                               :access="access"
                               value-type="number"
                               @edit="onCellEdit('month', mnth, ind, null, $event)"
              />
              <b-popover v-if="mnth < 12" :target="`kpi_mn_${ind.id.value}_${mnth}_${uuid}`" triggers="hover" :delay="delay">
                <template #title></template>
                <div>
                  <b-button size="sm" variant="outline-primary" @click="copyTillYear(ind, mnth)">Скопировать до конца года</b-button>
                </div>
              </b-popover>
            </td>
            <td style="padding: 0.25rem 0.5rem 0.25rem 0.5rem; text-align: center;">
              {{ sumYear(ind, baseYear) }}
            </td>
            <td v-for="yr in years" :key="'yr_' + (baseYear + yr)" style="padding: 0.25rem 0.5rem 0.25rem 0.5rem;">
              <cbs-budreq-cell
                :record="getRecord(constrDateString('year', yr), ind, null)"
                :key-value="keyVal"
                :indicator="ind"
                :access="access"
                value-type="number"
                @edit="onCellEdit('year', yr, ind, null, $event)"
              />
            </td>
          </tr>
          <tr v-if="isByProduct(ind) || ind.isfolder.value">
            <td class="table-secondary cell" style="position: sticky; left:0; z-index: 2;">
              {{ ind.code.value }}
            </td>
            <td class="table-secondary cell stick-two" style="position: sticky; z-index: 2;" :style="'left: ' + stick() + 'px;'">
              <feather-icon v-if="isByProduct(ind)"
                            size="16" style="cursor: pointer;"
                            :icon="ind.expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'"
                            @click="clickIndExpand(ind)"
              />
              {{ ind.name.value }}
              <span v-if="metric(ind)">, {{ metric(ind) }}</span>
            </td>
            <td colspan="18" class="table-secondary cell"></td>
          </tr>
          <tr v-if="isByProduct(ind) && ind.expanded" style="padding: 0;">
            <td colspan="18" style="padding: 0;">
              <cbs-collapse :trigger="ind.expanded" style="padding: 1rem;">
                <table class="table table-bordered">
                  <thead>
                    <tr :id="`th_${uuid}`">
                      <th style="position: sticky; left: 0; z-index: 2;">Продукт</th>
                      <th v-for="mn in monthnames" :key="'hm_' + mn">{{ mn + '-' + (baseYear - 2000) }}</th>
                      <th></th>
                      <th v-for="yr in years" :key="'hy_' + yr">{{ baseYear + yr }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="prod in products" :key="'ind_' + ind.id.value + '_prod_' + prod.id.value">
                      <td class="table-light" style="position: sticky; left: 0; z-index: 2;">
                        {{ prod.name.value }}
                        <span v-if="metric(prod)">, {{ metric(prod) }}</span>
                      </td>
                      <td v-for="mnth in months" :key="'mnth_' + mnth" style="padding: 0;">
                        <cbs-budreq-cell
                          :record="getRecord(constrDateString('month', mnth), ind, prod)"
                          :key-value="keyVal"
                          :indicator="ind"
                          @edit="onCellEdit('month', mnth, ind, prod, $event)"
                        />
                      </td>
                      <td style="padding: 0;"></td>
                      <td v-for="yr in years" :key="'yr_' + (baseYear + yr)" style="padding: 0;">
                        <cbs-budreq-cell
                          :record="getRecord(constrDateString('year', yr), ind, prod)"
                          :key-value="keyVal"
                          :indicator="ind"
                          @edit="onCellEdit('year', yr, ind, prod, $event)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </cbs-collapse>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <cbs-debug v-if="isRoot" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import CbsBudreqCell from '@/cubus/components/cbs-form/custom-forms/budget-request/CbsBudreqCell.vue'
import {
  BButton, BPopover,
} from 'bootstrap-vue'

export default {
  name: 'KbvBisTable',
  components: {
    BButton,
    BPopover,
    CbsCollapse,
    CbsBudreqCell,
    CbsDebug,
  },
  props: {
    scenario: {
      type: Object,
      default: null,
    },
    indicators: {
      type: Array,
      default: null,
    },
    baseYear: {
      type: Number,
      default: null,
    },
    objData: {
      type: Object,
      default: null,
    },
    keyByProduct: {
      type: String,
      default: null,
    },
    products: {
      type: Array,
      default: null,
    },
    keyMetric: {
      type: String,
      default: null,
    },
    keyScenario: {
      type: String,
      default: null,
    },
  },
  setup() {
    const session = useCubus.getSession()
    const isRoot = useCubus.isRoot()
    const uuid = useCubus.guid()
    const monthnames = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек']
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    const years = [1, 2, 3, 4]
    return {
      session,
      isRoot,
      uuid,
      monthnames,
      months,
      years,
    }
  },
  data() {
    return {
      objectsid: 'view_indicator_values_current_user',
      keyVal: 'indicator_value',
      keyDate: 'date',
      keyInd: 'budget_indicator',
      keyScn: 'scenario',
      keyProduct: 'budget_product',
      qryData: null,
      innerIndicators: JSON.parse(JSON.stringify(this.indicators)),
      dataReady: false,
      firstCol: null,
      access: 'ro',
      delay: { show: 1000, hide: 50 },
      uuid: null,
    }
  },
  created() {
    this.uuid = useCubus.guid()
    this.init()
  },
  methods: {
    init() {
      if (this.scenario.isactive.value === true) this.access = 'rw'
      this.loadQryData()
    },
    getRecord(dt, indicator, product) {
      if (!this.qryData) return null
      return this.qryData.recordset.records.find(r => r[this.keyInd].value === indicator.id.value
        && (product === null || r[this.keyProduct].value === product.id.value)
        && r[this.keyDate].value.startsWith(dt))
    },
    constrDateString(period, index) {
      if (period === 'month') {
        // return `01.${index < 10 ? '0' : ''}${index}.${this.baseYear}`
        return `${this.baseYear}-${index < 10 ? '0' : ''}${index}-01`
      }
      if (period === 'year') {
        // return `01.01.${(this.baseYear + index)}`
        return `${(this.baseYear + index)}-01-01`
      }
      return null
    },
    getNewRecord_v2(ind, dt, prod) {
      const res = {}
      this.qryData.columns.forEach(c => {
        res[c.key] = c.default
      })
      res.del.value = false
      res[this.keyScn].value = this.scenario[this.keyScenario].value
      res[this.keyScn].title = this.scenario[this.keyScenario].title
      res[this.keyDate].value = dt
      res[this.keyInd].value = ind.id.value
      res[this.keyInd].title = ind.name.value
      if (prod) {
        res[this.keyProduct].value = prod.id.value
        res[this.keyProduct].title = prod.name.value
      }
      return JSON.parse(JSON.stringify(res))
    },
    editRecord(rec, newValue) {
      const r = rec
      r[this.keyVal].value = newValue
      this.saveRecord(rec)
    },
    async saveRecord_v2(record) {
      try {
        const saved = await useCubus.saveRecord(this, { objectid: this.objData.object.id, record })
        this.afterSave(record, saved)
      } catch (e) { console.error(e) }
    },
    afterSave_v2(initRec, savedRec) {
      const i = initRec
      const s = savedRec
      s.status = 'saved'
      this.qryRequest.columns.forEach(fld => {
        if (fld.key !== 'actions') {
          i[fld.key].value = savedRec[fld.key].value
          i[fld.key].title = savedRec[fld.key].title
        }
      })
      i.status = 'saved'
    },
    getNewRecord(ind, dt, prod, value) {
      const res = {}
      this.qryData.columns.forEach(c => {
        res[c.entitysid] = c.default
      })
      res.del.value = false
      res[this.keyScn].value = this.scenario[this.keyScenario].value
      res[this.keyScn].title = this.scenario[this.keyScenario].title
      res[this.keyDate].value = dt
      res[this.keyInd].value = ind.id.value
      res[this.keyInd].title = ind.name.value
      if (prod) {
        res[this.keyProduct].value = prod.id.value
        res[this.keyProduct].title = prod.name.value
      }
      res[this.keyVal].value = value
      return JSON.parse(JSON.stringify(res))
    },
    getRecordToSave(srcRec, newValue, ind, prod, dt) {
      if (srcRec) {
        if (srcRec[this.keyVal].value !== newValue) {
          const s = srcRec
          s[this.keyVal].value = newValue
          s[this.keyVal].status = 'changed'
          s.status = 'changed'
          return srcRec
        }
        return null
      }
      if (newValue === 0) return null
      const newrec = this.getNewRecord(ind, dt, prod, newValue)
      return newrec
    },
    saveRecord_v1(record, caller) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'saverecord',
          param: {
            objectid: this.objData.object.id,
            record,
          },
        },
      })
        .then(response => {
          console.log('save record response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.threadSaveRecord(response.data.thread, record, caller)
          } else {
            useCubus.toastError(this, 'No thread name provided.')
          }
        })
        .catch(error => {
          console.log('save record error', error)
        })
    },
    threadSaveRecord(thread, record, caller) {
      useJwt.query({ query: { method: 'thread', param: { threadname: thread } } })
        .then(response => {
          console.log('threadSaveRecord response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread.status === 'error') {
            useCubus.toastError(this, response.data.thread.error)
          } else if (response.data.thread.status === 'done') {
            this.afterSave(record, response.data.thread.result.record)
          } else {
            this.delaySaveRecord(thread, record, caller)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    delaySaveRecord(thread, record, caller) {
      setTimeout(() => this.threadSaveRecord(thread, record, caller), 200)
    },
    afterSave(initRec, savedRec) {
      const s = savedRec
      const i = initRec
      s.status = 'saved'
      if (initRec.id.value === null) {
        this.addNewRecord(savedRec)
      } else {
        this.qryData.columns.forEach(fld => {
          i[fld.entitysid] = savedRec[fld.entitysid]
        })
        i.status = 'saved'
      }
    },
    addNewRecord(rec) {
      // this.records.push(rec)
      this.qryData.recordset.records.push(rec)
      // target.innerText = rec[this.keyVal].value
    },
    onCellEdit(period, periodNum, ind, prod, newValue) {
      const dt = this.constrDateString(period, periodNum)
      const rec = this.getRecord(dt, ind, prod)
      const newrec = this.getRecordToSave(rec, newValue, ind, prod, dt)
      if (newrec) {
        this.saveRecord(newrec, 'kbv_bis_table')
      }
    },
    async saveRecord(rec) {
      const savedRec = await useCubus.saveRecord(this, { keymode: 'sid', objectsid: this.objectsid, record: rec })
      this.afterSave(rec, savedRec)
    },
    isByProduct(ind) {
      return ind[this.keyByProduct].value === true
    },
    clickIndExpand(ind) {
      const i = ind
      if (ind.expanded) {
        i.expanded = !ind.expanded
      } else {
        this.$set(ind, 'expanded', true)
      }
    },
    metric(ind) {
      return ind[this.keyMetric] ? ind[this.keyMetric].title : null
    },
    stick() {
      const c = document.getElementById(`firstcol_${this.uuid}`)
      return c ? c.offsetWidth : 0
    },
    async loadQryData() {
      const params = {
        keymode: 'sid',
        objectsid: this.objectsid,
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'del' },
                  { type: { sid: 'value' }, value: false },
                ],
              },
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'scenario' },
                  { type: { sid: 'value' }, value: this.scenario[this.keyScenario].value },
                ],
              },
            ],
          },
        },
        pageSize: 100000,
      }
      this.qryData = await useCubus.loadQuery(this, params)
      // console.log('qryData', this.qryData)
      if (this.qryData) this.initQryData()
    },
    initQryData() {
      this.dataReady = true
      this.$nextTick(() => {
        this.stickSecondCol()
      })
    },
    stickSecondCol() {
      const offset = document.getElementById(`firstcol_${this.uuid}`).offsetWidth
      document.querySelectorAll('.stick-two').forEach(cell => {
        const c = cell
        c.style.left = `${offset}px`
      })
    },
    styleStick2() {
      return {
        left: `${this.stick()}px`,
      }
    },
    sumYear(ind, yr) {
      if (ind.horizontal_total.value === true) {
        const v = this.qryData.recordset.records.filter(r => r.budget_indicator.value === ind.id.value
          && r.date.value.slice(6, 10) === `${yr}`)
          .reduce((acc, rec) => acc + (rec.indicator_value.value || 0), 0)
        return this.formatted(v, 'number')
      }
      return null
    },
    formatted(value, valueType) {
      if (valueType === 'date') return this.toDate(value)
      if (valueType === 'number') return value ? Number(value).toLocaleString() : '\u00a0'
      return value
    },
    copyTillYear(ind, mnth) {
      const srcRec = this.getRecord(this.constrDateString('month', mnth), ind, null)
      if (!srcRec) return
      const srcValue = srcRec.indicator_value.value
      let i = mnth + 1
      while (i <= 12) {
        this.onCellEdit('month', i, ind, null, srcValue)
        i += 1
      }
    },
  },
}
</script>

<style scoped>
.tablespace {
  overflow: scroll;
  max-height: 75vh;
}
.stick-th {
  position: sticky;
  top: 0;
  z-index: 3;
}
.stick-th-1 {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 4
}
.stick-th-2 {
  position: sticky;
  top: 0;
  z-index: 4;
}
.cell {
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
}
</style>
